.main {
  @apply justify-center;
  @apply mb-6;

  .row {
    @apply flex-wrap gap-6;

    .buttonSeeAll {
      @apply bg-transparent text-text font-bold border-solid outline outline-1 border-gray-600 rounded-full py-2 px-4 mx-auto my-0 mb-6;

      svg {
      @apply w-[0.875rem] h-[0.875rem] ml-2;
      }
    }

    .noCampaign {
      @apply flex justify-center w-full;
    }
  }
}

@screen lg {
  .main {
    @apply px-0;

    .row {
      @apply gap-5;
    }
  }
}