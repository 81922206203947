.button {
  @apply flex items-center justify-center;
  @apply w-fit h-fit;
  @apply px-6 py-4;
  @apply bg-primary;
  @apply rounded-full;
  @apply text-white;
  @apply font-normal tracking-wider text-lg;
  @apply m-0;

  .icon {
    @apply flex;
    @apply w-min h-min w-max h-max;
    @apply text-white;
    @apply ml-4;

    svg, img {
      @apply flex;
      @apply w-5 h-5;
    }
  }

  .semiCircle {
    @apply w-6 h-6 relative;

    .border {
      @apply w-full h-full border-4 border-transparent border-t-white rounded-full animate-spin;
    }

    .message {
      @apply absolute top-0 left-0 w-full h-full flex items-center justify-center text-lg font-bold;
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@screen lg {
  .button {
    .label {}
    .icon {
      svg, img {}
    }
  }
}