.main {
  @apply justify-center;
  @apply px-4 mb-8;

  .row {
    @apply flex-wrap gap-6 justify-start;
  }
}

@screen sm {
  .main {
    .row {
      @apply px-0;
    }
  }
}

@screen lg {
  .main {
    @apply px-0 w-[992px];

    .row {
      @apply gap-5;
    }
  }
}