.card {
    @apply w-full;
    @apply rounded-2xl;
    @apply shadow-2xl;
    @apply duration-75;
    @apply flex-none;

    &:hover {
        transform: scale(1.03);
    }

    .image {
        @apply w-full h-48;
        @apply rounded-t-2xl;
        @apply object-cover;
    }

    .content {
        @apply flex flex-col p-4;
        @apply bg-background-alt;
        @apply rounded-b-2xl;
        @apply pb-11;

        .category {
            @apply text-text-base font-bold text-primary uppercase;
        }

        .title {
            @apply h-14;
            @apply mt-1;
            @apply text-text-lg font-bold text-text;
            @apply line-clamp-2
        }

        .details {
            @apply flex items-center justify-between mt-4 mb-1;
            @apply text-text-xs text-text-grey;
        }

        .progressBar {
            @apply relative overflow-hidden bg-neutral-200 rounded-full w-full h-1;

            .progressIndicator {
                @apply bg-primary rounded-full;
                @apply w-full h-full;
                @apply transition-transform duration-700 ease-in-out;
            }
        }

        .amountCollected {
            @apply py-2;
            @apply text-text-2xl text-primary;
        }

        .amountGoal {
            @apply text-text-sm font-bold text-text-grey;
        }
    }
}

@screen sm {
    .card {
        @apply w-[48%];

        .image {
            @apply w-full h-48;
        }
    }
}

@screen lg {
    .card {
        @apply w-[317px];

        .image {
            @apply w-[317px] h-48;
        }
    }
}