.main {
  @apply bg-background-main;

  .banner {
    @apply flex-col justify-center items-center;
    @apply w-full;
    @apply relative;

    &::before {
      @apply hidden;
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    img {
      @apply mt-2;
    }

    .bannerText {
      @apply flex-col items-start;
      @apply px-6 pb-6;
      @apply relative;
      z-index: 1;

      h1 {
        @apply text-2xl text-secondary text-left;
        @apply font-bold;
      }

      & > span {
        @apply text-lg text-[#117F73] text-left;
        @apply font-medium;
        @apply mt-2;
      }

      button {
        @apply mt-6;
      }
    }

    @screen sm {
      img {
        @apply w-[400px];
      }
    }

    @screen lg {
      @apply px-8;
      @apply flex-row;
      @apply max-w-screen-lg;
      @apply m-auto;

      img {
        @apply right-0 bottom-0;
      }

      .bannerText {
        @apply flex-col items-start;
        @apply w-3/5;
        @apply px-0;

        h1 {
          @apply text-3xl text-left leading-tight;
          @apply py-0 px-0 pr-8;
        }

        & > span {
          @apply pl-0 mt-4 text-xl;
        }

        button {
          @apply mt-10;
        }
      }
    }
  }
}
