.group-list {
  @apply flex flex-row overflow-x-auto;
  @apply w-screen px-4 py-8;
  @apply divide-x-2;

  .list-item {
    @apply h-max px-4;
    @apply text-text-lg font-bold text-text;

    & button {
      @apply p-2 whitespace-nowrap;
    }

    & a {
      @apply break-normal whitespace-nowrap;
    }

    &:first-child {
      @apply pl-0;
    }

    &.active {
      button {
        @apply px-4 rounded-full;
        @apply bg-primary-accent/50 text-primary;
      }
    }
  }

  .divider {
    @apply w-0.5 h-11 bg-border;
  }
}

@screen sm {
  .nav-list {
    @apply overflow-x-auto;

    .group-list {
      @apply w-full pt-4 pl-4 pb-8;
    }
  }
}

@screen lg {
  .nav-list {
    @apply w-full;
  }
}
