.container {
    @apply flex;
    width: 100%;
}

.row {
    @apply flex;
    width: 100vw;

    @screen lg {
        width: calc(100vw - 32px);
        max-width: 1024px;
        @apply justify-center;
    }
}

.column {
    @apply flex;
}